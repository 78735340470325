import {Component, OnInit, ViewChild} from "@angular/core";
import {
  DeploymentDto,
  DeploymentService,
  QueueDto
} from "@r3-iot/api-sigma";
import { AlertBannerService, R3CommonModule } from "@r3-iot/common";
import {ActivatedRoute, Router} from "@angular/router";
import { MatTable, MatTableDataSource, MatTableModule } from "@angular/material/table";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {DeploymentManagementService} from "../../deployment-management.service";
import { DatePipe } from "@angular/common";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";

@UntilDestroy()
@Component({
    selector: 'app-k-cell-queue',
    templateUrl: './k-cell-queue.component.html',
    styleUrls: ['./k-cell-queue.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, MatTableModule, MatPaginatorModule, DatePipe, R3CommonModule]
})

export class KCellQueueComponent implements OnInit {
  @ViewChild('queueTable') queueTable : MatTable<QueueDto>;
  @ViewChild('queuePaginator', {static: true}) queuePaginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  alertBannerDismiss = $localize`Dismiss`;
  alertBannerOk = $localize`OK`;
  data: any;

  queueDataSource = new MatTableDataSource<QueueDto>();
  displayedQueueColumns=['Action Type', 'DevEUI', 'Status', 'Created At', 'Completed At']
  deployment: DeploymentDto;
  queue: QueueDto[];
  loading = true;

  constructor(private router: Router, private deploymentsService: DeploymentService,
              private deploymentManagementService: DeploymentManagementService) {}

  ngOnInit(): void {
    this.deploymentManagementService.deployment$.pipe(untilDestroyed(this)).subscribe({
      next: (deployment: DeploymentDto) => {
        this.deploymentsService.v1DeploymentNameQueueDaysGet(deployment.name, '', 14)
          .pipe(untilDestroyed(this)).subscribe({
          next: (queue: QueueDto[]) => {
            this.queue = queue;
            this.queueDataSource.data = queue;
            this.queueDataSource.paginator = this.queuePaginator;
            this.queueDataSource.sort = this.sort;
            this.loading = false;
          }
        });
      }
    })

    this.queueDataSource.filterPredicate = (data: QueueDto, filter: string) => {
      return data.actionType.toLowerCase().includes(filter) || data.status.toLowerCase().includes(filter) || data.devEui?.toLowerCase().includes(filter);
    }
  }

  applyQueueFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.queueDataSource.filter = filterValue.trim().toLowerCase();
    if (this.queueDataSource.paginator) {
      this.queueDataSource.paginator.firstPage();
    }
  }
}
