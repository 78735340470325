import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {
  DeploymentDto,
  DeploymentService, DeviceDataDto
} from "@r3-iot/api-sigma";
import {AlertBannerService} from "@r3-iot/common";
import {combineLatest, finalize, take} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import { MatTable, MatTableDataSource, MatTableModule } from "@angular/material/table";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {DevicesDataSource} from "./devices-data.source";
import {DeploymentManagementService} from "../../deployment-management.service";
import { DatePipe } from "@angular/common";

@UntilDestroy()
@Component({
    selector: 'app-devices-data',
    templateUrl: './devices-data.component.html',
    styleUrls: ['./devices-data.component.scss'],
    standalone: true,
    imports: [MatTableModule, MatPaginatorModule, DatePipe]
})

export class DevicesDataComponent implements OnInit, AfterViewInit {
  @ViewChild('deviceDataTable') devicesTable : MatTable<DeviceDataDto>;
  @ViewChild('deviceDataPaginator', {static: true}) deviceDataPaginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;
  alertBannerDismiss = $localize`Dismiss`;
  alertBannerOk = $localize`OK`;
  data: any;
  deviceDataSource = new MatTableDataSource<string>();
  form: UntypedFormGroup;
  deployment: DeploymentDto;
  saving = false;
  loading = true;
  deviceDataTableSource: DevicesDataSource;
  displayedDeviceDataColumns=['Message', 'DevEUI', 'Created At']


  constructor(private router: Router, private dialog: MatDialog, private deploymentsService: DeploymentService,
              private deploymentManagementService: DeploymentManagementService,
              public alertBannerService: AlertBannerService, private route: ActivatedRoute) {
    this.deviceDataTableSource = new DevicesDataSource();

  }

  ngOnInit(): void {
    this.deploymentManagementService.deployment$.pipe(untilDestroyed(this)).subscribe({
      next: (deployment: DeploymentDto) => {
        this.deployment = deployment;
        this.loadDeviceData();
      }
    })
    this.deviceDataSource.filterPredicate = (data: string, filter: string) => {
      return data.toLowerCase().includes(filter);
    }
  }

  ngAfterViewInit(): void {
    this.deviceDataPaginator.page
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loadDeviceData()
      });
  }

  loadDeviceData(): void {
    this.deviceDataTableSource.getPageData(
        this.deploymentsService.v1DeploymentNameDevicedataDevEuiGet(this.deployment.name, "", null,
            null, this.deviceDataPaginator.pageSize, this.deviceDataPaginator.pageIndex, "DESC", null, "created_at",
            'response')
        .pipe(take(1), finalize(() => {
          this.loading = false;
        })));
  }

  goToSummary(): void {
    this.router.navigate(['../summary'], { relativeTo: this.route });
  }
}
