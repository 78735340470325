import { enableProdMode, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { environment } from './environments/environment';
import {Auth} from 'aws-amplify';
import { AppComponent } from './app/app.component';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SharedModule } from './app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import {provideRouter, RouterModule, withComponentInputBinding} from '@angular/router';
import { LayoutModule } from '@angular/cdk/layout';
import { DeploymentDeviceModule } from './app/components/deployments/deployment-management/devices/devices-view/deployment-device/deployment-device.module';
import { DeploymentManagementModule } from './app/components/deployments/deployment-management/deployment-management.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertBannerService, R3CommonModule, AlertBannerModule } from '@r3-iot/common';
import { Configuration as ApiConfiguration, ApiModule } from '@r3-iot/api-sigma';
import { HttpHeadersInterceptor } from './app/shared/interceptors/http-headers.interceptor';
import { HttpErrorInterceptor } from './app/shared/interceptors/http-error.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import {ROUTES} from "./app/routes";
if (environment.production) {
  enableProdMode();
}
if (environment.offlineMode) {
// Amplify.Logger.LOG_LEVEL = 'DEBUG';

// https://angularquestions.com/2020/10/14/angular-10-autherror-error-amplify-has-not-been-configured-correctly/
  const awsconfig = {
    Auth: {

// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
//     identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
// REQUIRED - Amazon Cognito Region
      region: 'eu-west-2',

// OPTIONAL - Amazon Cognito Federated Identity Pool Region
// Required only if it's different from Amazon Cognito Region
//    identityPoolRegion: 'eu-west-2',

// OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: environment.userPoolId,
// set endpoint if running in offlinemode
      endpoint: "http://localhost:9229/",

// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: environment.clientId,

// OPTIONAL - Enforce users authentication prior to accessing AWS resources or not
      mandatorySignIn: false,

// OPTIONAL - Configuration for cookie storage
// Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
//     cookieStorage: {
// // REQUIRED - Cookie domain (only required if cookieStorage is provided)
//       domain: '.yourdomain.com',
// // OPTIONAL - Cookie path
//       path: '/',
// // OPTIONAL - Cookie expiration in days
//       expires: 365,
// // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
//       // sameSite: "strict",
//       sameSite: 'lax',
// // OPTIONAL - Cookie secure flag
// // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
//       secure: true
//     },

// OPTIONAL - customized storage object
//     storage: MyStorage,
//     storage: null,

// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_PASSWORD_AUTH',

// OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
//     clientMetadata: {myCustomKey: 'myCustomValue'},

// OPTIONAL - Hosted UI configuration
//     oauth: {
//       domain: 'http://localhost:9229',
//       scope: [
//         // 'phone'
//         'email',
//         // 'profile',
//         // 'openid',
//         // 'aws.cognito.signin.users.admin'
//       ],
//       redirectSignIn: 'http://localhost:4201/, http://localhost:4201/login',
//       redirectSignOut: 'http://localhost:4201/',
//       responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
//
//       // options?: object;
//       // urlOpener?: (url: string, redirectUrl: string) => Promise<any>;
//     }
    },

    Analytics: {
      disabled: true,
    }
  };
// remove  CommonJS or AMD dependencies optimization bailouts warnings for AWS Amplify using Angular v10
// https://gist.github.com/gsans/8982c126c4fef668c094ff288f04241b

  Auth.configure(awsconfig);


} else {
// Amplify.Logger.LOG_LEVEL = 'DEBUG';

// https://angularquestions.com/2020/10/14/angular-10-autherror-error-amplify-has-not-been-configured-correctly/
  const awsconfig = {
    Auth: {

      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      //     identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
      // REQUIRED - Amazon Cognito Region
      region: 'eu-west-2',
      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      //    identityPoolRegion: 'eu-west-2',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: environment.userPoolId,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: environment.clientId,

      // OPTIONAL - Enforce users authentication prior to accessing AWS resources or not
      mandatorySignIn: false,

      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      //     cookieStorage: {
      // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      //       domain: '.yourdomain.com',
      // // OPTIONAL - Cookie path
      //       path: '/',
      // // OPTIONAL - Cookie expiration in days
      //       expires: 365,
      // // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      //       // sameSite: "strict",
      //       sameSite: 'lax',
      // // OPTIONAL - Cookie secure flag
      // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      //       secure: true
      //     },

      // OPTIONAL - customized storage object
      //     storage: MyStorage,
      //     storage: null,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      //     authenticationFlowType: 'USER_PASSWORD_AUTH',

      // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
      //     clientMetadata: {myCustomKey: 'myCustomValue'},

      // OPTIONAL - Hosted UI configuration
      oauth: {

        domain: 'https://r3iot.auth.eu-west-2.amazoncognito.com',
        scope: [
          // 'phone'
          'email',
          'profile',
          'openid',
          'aws.cognito.signin.users.admin'
        ],
        redirectSignIn: 'http://localhost:4201/, http://localhost:4201/login',
        redirectSignOut: 'http://localhost:4201/',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code

        // options?: object;
        // urlOpener?: (url: string, redirectUrl: string) => Promise<any>;
      }
    },

    Analytics: {
      disabled: true,
    }
  };
  // remove  CommonJS or AMD dependencies optimization bailouts warnings for AWS Amplify using Angular v10
  // https://gist.github.com/gsans/8982c126c4fef668c094ff288f04241b

  Auth.configure(awsconfig);
}


bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(ApiModule, AmplifyAuthenticatorModule, BrowserModule, DeploymentManagementModule.forRoot({
            RolesConfig: [
                'r3_developer',
                'r3_admin',
                'r3_support',
                'connect_admin'
            ]
        }), DeploymentDeviceModule.forRoot({
            RolesConfig: [
                'r3_developer',
                'r3_admin',
                'r3_support',
                'connect_admin'
            ]
        }), LayoutModule, RouterModule, ReactiveFormsModule, SharedModule, R3CommonModule.forRoot({
            RolesConfig: [
                'r3_developer',
                'r3_admin',
                'r3_support',
                'connect_admin',
                'mcs_admin',
                'mcs_site_admin',
                'mcs_site_user'
            ],
            "Environment": environment
        }), AlertBannerModule, MatDatepickerModule, MatMomentDateModule, NgxMatTimepickerModule, NgxMatMomentModule),
        provideRouter(ROUTES, withComponentInputBinding()),
        DatePipe,
        DecimalPipe,
        PercentPipe,
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpHeadersInterceptor, multi: true },
        { provide: 'STORAGE_PREFIX', useValue: 'connect' },
        { provide: LOCALE_ID, useValue: 'en' },
        { provide: 'Environment', useValue: environment },
        {
            provide: ApiConfiguration,
            useFactory: () => new ApiConfiguration({
                basePath: environment.apiAddress
            }),
            deps: [],
            multi: false
        },
        AlertBannerService,
        MatSnackBar,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi())
    ]
})
  // eslint-disable-next-line no-console
  .catch(err => console.error(err));
