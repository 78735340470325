import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {from, Observable, of, pipe, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import Auth from '@aws-amplify/auth';
import {catchError, map, switchMap} from "rxjs/operators";

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(Auth.currentSession())
      .pipe(
        switchMap(authenticatedUser => {
          const headers = new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*',
            'Authorization': `Bearer ${authenticatedUser.getIdToken().getJwtToken()}`
          });

          const requestClone = request.clone({
            headers
          });
          return next.handle(requestClone);
        })
      );
  }
}
