import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthenticatorService} from '@aws-amplify/ui-angular';
import Auth from "@aws-amplify/auth";

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard {
  constructor(private authenticatorService: AuthenticatorService, private router: Router) {}

  tokenExpired(user: any): boolean{

    const expiration = user.signInUserSession.accessToken.payload['exp'];

    const currentUnixTime = new Date().getTime() / 1000

    if (currentUnixTime > expiration) {
      return true;
    }
    else {
      return false;
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>{
    return Auth.currentAuthenticatedUser()
      .then(user => {


        if (this.tokenExpired(user)) {
          // this.router.navigate(['/logout']);
          // return false;
        }
        // No role restrictions assigned to route, so allow any authenticated users.
        if (!route.data["roles"] || route.data["roles"].length === 0) { return true; }

        // If roles are present, check our token for authorization
        if (
          user["signInUserSession"].accessToken.payload['cognito:groups'] &&
          user["signInUserSession"].accessToken.payload['cognito:groups'].filter(value => route.data["roles"].includes(value).length > 0)
        ) {
            return true;
        }

        this.router.navigate(['/logout']);
        return false;
      })
      .catch(err => {
        this.router.navigate(['/logout']);
        return false;
      });
  }
}
